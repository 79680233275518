import React from 'react'
import { Link } from 'gatsby'
import {
  faBriefcase,
  faFileAlt,
  faPhone,
  faCrown,
  faHandshake,
  faGraduationCap,
  faMoneyCheck,
  faFileContract,
  faPoundSign,
  faFlask,
  faSack,
  faShieldAlt,
} from '@fortawesome/pro-light-svg-icons'

import dayToDayImg from '../../images/svg/how-it-works/day-to-day.svg'
import monthQuarterEndImg from '../../images/svg/how-it-works/month-quarter-end.svg'
import yearEndImg from '../../images/svg/how-it-works/year-end.svg'
import standalone from '../../images/svg/how-it-works/standalone.svg'
import specialised from '../../images/svg/how-it-works/specialised.svg'

import SiteLayout from '../../layout/SiteLayout'
import SEO from '../../components/SEO'
import TimelineStep from '../../components/Timeline'
import Section from '../../components/Section'
import SquircleIcon from '../../components/SquircleIcon'
import { Title, TitleContainer, TitleIntro } from '../../components/Title'
import { Intro, IntroTitle, IntroText } from '../../components/Intro'
import { Items, Item, ItemTitle } from '../../components/Items'
import { CtaCurve, CtaCurveTitle } from '../../components/CtaCurve'
import { PricingPlanTable, PricingPlan } from '../../components/PricingPlan'
import {
  CircleIconGroup,
  CircleIconGroupTitle,
} from '../../components/CircleIcon'
import {
  SideBySide,
  SideBySideImg,
  SideBySideText,
  SideBySideTitle,
} from '../../components/SideBySide'

const StartUps = () => {
  return (
    <SiteLayout header="light">
      <SEO title="Start up accountants" />
      <TitleContainer>
        <Title>Accounting for start ups</Title>
        <TitleIntro>
          Creating and running a business can be hard work. Our start up
          accounting services have been built around giving you the fast-paced
          support you need so you can focus on getting your business off the
          ground.
        </TitleIntro>
      </TitleContainer>
      <Section>
        <Intro>
          <IntroTitle>How does it work?</IntroTitle>
          <IntroText>
            With AirAccounting keeping your books up to date couldn’t be quicker
            or easier. All you have to do is use our mobile app to send us your
            expenses and create your invoices and we'll take care of everything
            for you.
          </IntroText>
        </Intro>
        <TimelineStep
          img={dayToDayImg}
          imgAlt="Day-to-day"
          count="01"
          heading="Day-to-day bookkeeping"
          lineEnd
        >
          <ul className="bullet-list">
            <li>
              Take photos of your expenses using our mobile app to send them
              directly to your accountant.
            </li>
            <li>
              Connect your bank accounts to our bank feed system and we’ll
              automatically download your bank statements.
            </li>
            <li>
              Create professional looking invoices to send to your customers
              using our invoicing tool.
            </li>
          </ul>
        </TimelineStep>
        <TimelineStep
          img={monthQuarterEndImg}
          imgAlt="End of month/quarter"
          heading="Month or quarter end review"
          count="02"
          lineStart
          lineEnd
          reverse
        >
          <ul className="bullet-list">
            <li>
              We'll create reports based on what you send us so you can track
              and monitor sales, costs, profits and growth.
            </li>
            <li>
              Your start up accountant will review the numbers to identify
              future cash flow problems well in advance.
            </li>
            <li>
              Our tax team prepares your actual and estimated tax bills that you
              can view and manage before they arrive.
            </li>
          </ul>
        </TimelineStep>
        <TimelineStep
          img={yearEndImg}
          imgAlt="End of year"
          count="03"
          heading="Finalising your year end"
          lineStart
        >
          <ul className="bullet-list">
            <li>
              Your accounts are prepared by qualified accountants to send to
              lenders, investors and other stakeholders.
            </li>
            <li>
              We prepare your tax returns that comply with tax laws whilst
              claiming all available reliefs and expenses.
            </li>
            <li>
              All legal filings for Companies House and HMRC are submitted on
              your behalf.
            </li>
          </ul>
        </TimelineStep>
      </Section>
      <Section color="gray" bubbles>
        <Intro>
          <IntroTitle>What is your pricing?</IntroTitle>
          <IntroText>
            We offer an amazing level of support when you sign up to our small
            business accounting service, with various add-ons you can choose to
            sign up to should you require them.
          </IntroText>
        </Intro>
        <PricingPlanTable>
          <PricingPlan
            planType="Small Business Accounting"
            planName="Sole trader"
            planPrice="£25.50"
            planPeriod="+ VAT per month"
            planStandardServices={[
              'Tax registrations',
              'Unlimited advice',
              'HMRC representation',
              'Partner network',
              'Training',
              'Sole trader accounts',
              'Self-assessment tax return',
            ]}
            planAddOnServices={[
              'Payroll for staff',
              'VAT returns',
              'CIS returns',
            ]}
          >
            <Link
              className="btn btn-primary d-block mx-auto"
              to="/pricing/build-your-package"
            >
              Build your package
            </Link>
            <Link
              className="btn btn-link my-3 d-block"
              to="/pricing/small-business-accounting"
            >
              Learn more
            </Link>
          </PricingPlan>
          <PricingPlan
            planType="Small Business Accounting"
            planName="Limited company"
            planPrice="£79.50"
            planPeriod="+ VAT per month"
            planStandardServices={[
              'Tax registrations',
              'Unlimited advice',
              'HMRC representation',
              'Partner network',
              'Training',
              'Limited company setup',
              'Limited company accounts',
              'Corporation Tax return',
              'Payroll for 2 directors',
              'Tax return for 2 directors',
            ]}
            planAddOnServices={[
              'Payroll for staff',
              'VAT returns',
              'CIS returns',
              'Company secretarial',
            ]}
          >
            <Link
              className="btn btn-primary d-block mx-auto"
              to="/pricing/build-your-package"
            >
              Build your package
            </Link>
            <Link
              className="btn btn-link my-3 d-block"
              to="/pricing/small-business-accounting"
            >
              Learn more
            </Link>
          </PricingPlan>
          <PricingPlan
            planType="Small Business Accounting"
            planName="Partnership"
            planPrice="£48.50"
            planPeriod="+ VAT per month"
            planStandardServices={[
              'Tax registrations',
              'Unlimited advice',
              'HMRC representation',
              'Partner network',
              'Training',
              'Partnership accounts',
              'Tax return for 2 partners',
            ]}
            planAddOnServices={[
              'Payroll for staff',
              'VAT returns',
              'CIS returns',
            ]}
          >
            <Link
              className="btn btn-primary d-block mx-auto"
              to="/pricing/build-your-package"
            >
              Build your package
            </Link>
            <Link
              className="btn btn-link my-3 d-block"
              to="/pricing/small-business-accounting"
            >
              Learn more
            </Link>
          </PricingPlan>
        </PricingPlanTable>
      </Section>
      <Section>
        <Intro>
          <IntroTitle>What's included?</IntroTitle>
          <IntroText>
            Every business owner needs help in the early stages, which is why as
            your start up accountants we give you all of the below services as
            standard when you sign up to our small business accounting service.
          </IntroText>
        </Intro>
        <Items>
          <Item>
            <SquircleIcon color="purple" icon={faBriefcase} />
            <ItemTitle>Limited company setup</ItemTitle>
            <p>
              Running your start up through a limited company protects your
              personal assets, improves your reputation and has numerous tax
              advantages. Our team can register your limited company within 24
              hours so you can gain the benefits fast.
            </p>
          </Item>
          <Item>
            <SquircleIcon color="green" icon={faFileAlt} />
            <ItemTitle>Tax registrations</ItemTitle>
            <p>
              It can be difficult to know what taxes apply to your business.
              With various taxes such as VAT, PAYE and Corporation Tax all
              having their own rules it's easy to make a mistake. Our start up
              accountants carry out checks and register you for any taxes
              necessary.
            </p>
          </Item>
          <Item>
            <SquircleIcon color="orange" icon={faPhone} />
            <ItemTitle>Unlimited advice</ItemTitle>
            <p>
              Our team are always on hand to answer any questions you may have
              and to guide you in the right direction. We'll tell you what
              expenses you can claim for, what you can do to reduce your tax
              bills, how much money you're making and so much more.
            </p>
          </Item>
          <Item>
            <SquircleIcon color="yellow" icon={faCrown} />
            <ItemTitle>HMRC representation</ItemTitle>
            <p>
              Dealing with HMRC can be intimidating and stressful. Our experts
              handle all contact with HMRC on your behalf by approaching each
              scenario with great care and ensuring your taxpayer rights are
              upheld.
            </p>
          </Item>
          <Item>
            <SquircleIcon color="pink" icon={faHandshake} />
            <ItemTitle>Partner network</ItemTitle>
            <p>
              We've partnered with some truly great businesses to help our
              clients get the support and services they need. From bank accounts
              to insurance, business loans to legal help, you have access to
              them all.
            </p>
          </Item>
          <Item>
            <SquircleIcon color="blue" icon={faGraduationCap} />
            <ItemTitle>Training</ItemTitle>
            <p>
              Keeping an eye on the numbers is a key ingredient to success. Our
              start up accountants will work with you to help you understand
              your business's performance and other useful information.
            </p>
          </Item>
        </Items>
      </Section>
      <Section color="gray">
        <SideBySide reverse>
          <SideBySideText>
            <SideBySideTitle>Don't need the full service?</SideBySideTitle>
            <p>
              We offer standalone services for those business owners that just
              need a little helping hand.
            </p>
            <div className="mt-6">
              <CircleIconGroup icon={faMoneyCheck} color="primary">
                <CircleIconGroupTitle>Payroll & pension</CircleIconGroupTitle>
                <p>
                  Taxes and deductions calculated and payslips prepared for your
                  staff.
                </p>
              </CircleIconGroup>
              <CircleIconGroup icon={faFileContract} color="orange">
                <CircleIconGroupTitle>
                  Catch up year end accounts
                </CircleIconGroupTitle>
                <p>
                  Preparing accounts to meet deadlines and to get your books
                  back up-to-date.
                </p>
              </CircleIconGroup>
              <CircleIconGroup icon={faPoundSign} color="green">
                <CircleIconGroupTitle>
                  Personal tax returns
                </CircleIconGroupTitle>
                <p>
                  Completing and submitting your personal tax return to report
                  your income and capital gains.
                </p>
              </CircleIconGroup>
            </div>
            <Link
              className="btn btn-primary-light"
              to="/pricing/standalone-services"
            >
              Learn more
            </Link>
          </SideBySideText>
          <SideBySideImg src={standalone} alt="standalone services" />
        </SideBySide>
        <SideBySide>
          <SideBySideImg src={specialised} alt="specialised services" />
          <SideBySideText>
            <SideBySideTitle>Need expert help?</SideBySideTitle>
            <p>
              Our specialised services carried out by experienced professionals
              can make a real impact to you and your business.
            </p>
            <div className="mt-6">
              <CircleIconGroup icon={faFlask} color="primary">
                <CircleIconGroupTitle>
                  Research and Development (R&D)
                </CircleIconGroupTitle>
                <p>
                  Assessing, preparing and submitting detailed R&D tax credit
                  claims on a no-win no-fee basis.
                </p>
              </CircleIconGroup>
              <CircleIconGroup icon={faSack} color="orange">
                <CircleIconGroupTitle>Fundraising</CircleIconGroupTitle>
                <p>
                  Through our network of partners we can help raise funds for
                  your business to grow or ease cashflow.
                </p>
              </CircleIconGroup>
              <CircleIconGroup icon={faShieldAlt} color="green">
                <CircleIconGroupTitle>Tax investigatons</CircleIconGroupTitle>
                <p>
                  We can take full control of a tax investigation to minimise
                  the risk of big bills.
                </p>
              </CircleIconGroup>
            </div>
            <Link
              className="btn btn-green-light"
              to="/pricing/standalone-services"
            >
              Get in touch
            </Link>
          </SideBySideText>
        </SideBySide>
      </Section>
      <CtaCurve>
        <CtaCurveTitle>Have any questions?</CtaCurveTitle>
        <p>We can call you anytime between 9am - 5pm Monday to Friday.</p>
        <Link className="btn btn-primary-invert" to="/call-back">
          Request a call back
        </Link>
      </CtaCurve>
    </SiteLayout>
  )
}

export default StartUps
